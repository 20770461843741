import axios from 'axios'

export const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async show( team_id, payload ) {

		return client.get( '/api/team/' + team_id, payload )

	},

	async update( team_id, payload ) {

		return client.put( '/api/team/' + team_id, payload )

	},

	async store_media( team_id, payload ) {

		return client.post( '/api/team/' + team_id + '/media', payload )

	},

}