import axios from 'axios'

export const wordpress_client = axios.create({});

export default {
	async plugins( keyword ) {
		if ( keyword ) {
			keyword = '&search=' + keyword;
		}
		return wordpress_client.get( 'https://api.wordpress.org/plugins/info/1.2/?action=query_plugins&per_page=10' + keyword )
	},
};