<template>
	
	<div :class="( group_class ? group_class : 'mb-3' )">

		<label v-if="![ 'toggle', 'checkbox', 'radio' ].includes( type ) && label && !icon" :for="name" class="form-label fw-bold" :class="label_class ? label_class : ''" v-html="label + ( required ? ' *' : '')"></label>

		<label v-if="![ 'toggle', 'checkbox', 'radio' ].includes( type ) && label && icon" :for="name" class="form-label fw-bold d-flex align-items-center" :class="label_class ? label_class : ''">
			<span class="material-icons-outlined me-2">{{ icon }}</span>
			{{ label}}
		</label>

		<select 
			v-if="type == 'select'"
			:class="( field_class ? field_class : 'form-select' )"
			:id="name"
			:placeholder="placeholder"
			v-model="value"
			:required="required"
		>
			<option :value="option.value" v-for="( option, option_key ) in options" :key="'option-' + option_key">{{ option.name }}</option>
		</select>

		<div v-else-if="type == 'toggle'" class="form-check form-switch d-flex">
			<span><input class="form-check-input mt-2" type="checkbox" role="switch" :id="name" v-model="value"></span>
			<label class="form-check-label ms-2" :for="name" v-html="label"></label>
		</div>

		<div v-else-if="type == 'checkbox'" class="form-check d-flex">
			<span><input class="form-check-input mt-2" type="checkbox" role="switch" :id="name" v-model="value" :value="( default_value ? default_value : true )"></span>
			<label class="form-check-label ms-2" :for="name" v-html="label"></label>
		</div>

		<div v-else-if="type == 'radio'" class="form-check d-flex">
			<span><input class="form-check-input mt-2" type="radio" role="switch" :id="name" v-model="value" :value="( default_value ? default_value : true )"></span>
			<label class="form-check-label ms-2" :for="name" v-html="label"></label>
		</div>

		<div class="w-100" v-else-if="type == 'file'">
			<div v-if="loading" class="flex-grow-1">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>

			<label v-else style="cursor: pointer;" class="simple-file-upload d-flex align-items-center btn btn-outline-primary">
				<span class="material-icons-outlined me-2">attach_file</span>
				<span>{{ placeholder }}</span>
				<input 
					type="file" 
					:accept="accept ? accept : 'image/*'" 
					@change="( !team_id ? store_media($event) : store_media_team($event) )" 
					class="d-none"
				>
			</label>
		</div>

		<div class="w-100" v-else-if="type == 'brand_file'">
			<div v-if="loading" class="flex-grow-1">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>

			<label v-else style="cursor: pointer;" class="simple-file-upload d-flex align-items-center justify-content-end btn btn-link px-0 flex-grow-1">
				<span>{{ placeholder }}</span>
				<input 
					type="file" 
					:accept="accept ? accept : 'image/*'" 
					@change="( !team_id ? store_media($event) : store_media_team($event) )" 
					class="d-none"
				>
			</label>
		</div>

		<div class="position-relative w-100" v-else-if="type == 'color'">

			<div class="dropdown w-100">
				<button class="w-100 btn btn-outline-primary dropdown-toggle w-100 form-control text-start d-flex align-items-center" type="button" :id="'color-' + name" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
					<span style="width: 30px; height: 30px;" class="me-2 rounded border border-primary" :style="'background: ' + value + ';'"></span>
					<span class="flex-grow-1">{{ value ? value : 'Select color' }}</span>
				</button>
				<ul class="dropdown-menu p-0" :aria-labelledby="'color-' + name">
					<li>
						<color-picker
							:isWidget="true"
							format="hex"
							useType="pure"
							:disableAlpha="true"
							:disableHistory="true"
							:pureColor="value"
							@pureColorChange="changeColor"
							class="color-picker"
						/>
					</li>
					<li>
						<div class="p-2">
							<button type="button" v-on:click="[$emit('update:modelValue', color), updateColor()]" class="btn btn-primary w-100">Update</button>
						</div>
					</li>
				</ul>
			</div>
			
		</div>

		<div class="position-relative" v-else-if="type == 'brand_color'">

			<div class="dropdown">
				<button class="btn btn-link px-0 text-end d-flex align-items-center justify-content-end ms-2" type="button" :id="'color-' + name" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
					<span class="flex-grow-1">Select color</span>
				</button>
				<ul class="dropdown-menu p-0" :aria-labelledby="'color-' + name">
					<li>
						<color-picker
							:isWidget="true"
							format="hex"
							useType="pure"
							:disableAlpha="true"
							:disableHistory="true"
							:pureColor="value"
							@pureColorChange="changeColor"
							class="color-picker"
						/>
					</li>
					<li>
						<div class="p-2">
							<button type="button" v-on:click="[$emit('update:modelValue', color), updateColor()]" class="btn btn-primary w-100">Update</button>
						</div>
					</li>
				</ul>
			</div>
			
		</div>

		<div v-else-if="type == 'font'" class=" align-items-center justify-content-between flex-grow-1" :class="!is_thin ? 'd-flex' : ''">

			<p v-if="value && value['family']" class="mb-0 " :class="!is_thin ? 'ms-2' : ''">{{ value['family'] }} ({{ value['weight'] }})</p>
			<span v-else :class="!is_thin ? 'ms-2' : ''">Use default font</span>

			<div class="d-flex align-items-center">
				<button data-bs-toggle="modal" :data-bs-target="'#font-' + input_key + '-modal'" class="btn btn-link px-0" type="button">
					<span class="flex-grow-1">Select font</span>
				</button>
				<button @click="value = null" class="btn btn-link px-0 ms-4" type="button">
					<span class="flex-grow-1">Reset font</span>
				</button>
			</div>

		</div>

		<div v-else-if="type == 'quill'" class="h-100 position-relative d-flex flex-column">
			
			<p v-if="!strip_tags( internalContent )" class="position-absolute text-center" style="top: 104px; left: 50px; color: #ccc;">
				Start typing your page content...
			</p>
			<QuillEditor 
				theme="snow" 
				:ref="'quill-' + name"
				:toolbar="'#quill-toolbar-' + name"
				:options="editorOptions"
				contentType="html"
				v-model:content="internalContent"
			>
				<template #toolbar>
					<div :id="'quill-toolbar-' + name" class="bg-white position-sticky top-0 border-start-0 border-top-0 border-end-0 d-flex align-items-center justify-content-between p-0" style="z-index: 10">
						<div class="d-flex align-items-center p-2">
							<select class="ql-header">
								<option selected></option>
								<option value="1">Heading 1</option>
								<option value="2">Heading 2</option>
								<option value="3">Heading 3</option>
							</select>
							<button class="ql-list" value="bullet"></button>
							<button class="ql-link" value="button">Button</button>
							<select class="ql-custom">
								<option value="" selected>Add section</option>
								<option value="media">Media Gallery</option>
								<option value="cta">Call to Action</option>
								<option value="form">Form</option>
								<option value="logos">Client / Partner Logos</option>
								<option value="posts">Related Pages</option>
								<option value="subposts">Sub Pages</option>
							</select>
						</div>

						<div class="btn-group">
							<button v-if="is_ai" v-on:click="$emit('update:isAi', true)" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-start m-0" value="button" style="width: auto; height: auto;" data-bs-toggle="tooltip" data-bs-placement="top" title="Let Bella assist in writing your content" >
								<span class="material-icons-outlined me-2">auto_fix_high</span>
								Generate
							</button>

							<button v-if="is_format" v-on:click="$emit('update:isFormat', true)" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-start m-0" value="button" style="width: auto; height: auto;" data-bs-toggle="tooltip" data-bs-placement="top" title="Format your content to work perfectly on a webpage" >
								<span class="material-icons-outlined me-2">title</span>
								Format
							</button>

							<div class="m-0" data-bs-toggle="tooltip" data-bs-placement="top" title="See what content will look like on a webpage">
								<button class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-start m-0" value="button" style="width: auto; height: auto;" data-bs-toggle="modal" data-bs-target="#preview-modal">
									<span class="material-icons-outlined me-2">web</span>
									Preview
								</button>
							</div>
						</div>
					</div>
				</template>

			</QuillEditor>
		</div>

		<textarea 
			v-else-if="type == 'textarea'"
			:id="name" 
			:name="name" 
			:type="type" 
			:placeholder="( placeholder ? placeholder : '' )" 
			:class="( field_class ? field_class : 'form-control' )" 
			:required="required" 
			v-model="value"
			autofocus
			style="min-height: 200px;" 
		></textarea>

		<div v-else-if="type == 'options'" class="row row-cols-2 position-relative">
			<div class="h-100 border-end position-absolute top-0 start-50 translate-middle-x p-0" style="width: 1px;"></div>
			<div v-for="( row, row_key ) in value" class="col mb-4" :key="'key-' + row_key">
				<div v-if="options_edit === null || options_edit != row_key" class="h-100 mb-0 d-flex flex-column border-bottom p-0">
					<div class="pb-0 flex-grow-1" :class="row_key % 2 == 0 ? 'p-4 ps-0' : 'p-4 pe-0'">
						<p class="h3 fw-bold">{{ value[row_key]['name'] }}</p>
						<p class="small">{{ value[row_key]['description'] }}</p>
					</div>
					<div class="mt-3 d-flex align-items-center justify-content-between small" :class="row_key % 2 == 0 ? 'p-4 ps-0' : 'p-4 pe-0'">
						<button type="button" v-on:click="options_edit = row_key" class="bg-transparent border-0 text-primary d-block d-flex align-items-center">
							<span class="material-icons-outlined me-2">edit</span>
							Edit
						</button>
						<button type="button" v-on:click="remove_option( row_key )" class="bg-transparent p-0 border-0 text-primary d-flex align-items-center text-danger">
							<span class="material-icons-outlined me-2">close</span>
							Remove
						</button>
					</div>
				</div>
				<div v-else-if="options_edit == row_key" class="card h-100 p-4 mb-0">
					<div class="mb-3">
						<label :for="name + '-' + row_key + '-name'" class="form-label">Name</label>
						<input 
							:id="name + '-' + row_key + '-name'" 
							:name="name + '-' + row_key + '-name'" 
							:type="'text'" 
							:class="( field_class ? field_class : 'form-control' )"
							v-model="value[row_key]['name']"
							autofocus
						>
					</div>
					<div class="mb-3">
						<label :for="name + '-' + row_key + '-description'" class="form-label">Description</label>
						<textarea 
							:id="name + '-' + row_key + '-description'" 
							:name="name + '-' + row_key + '-description'"
							:class="( field_class ? field_class : 'form-control' )"
							v-model="value[row_key]['description']"
							autofocus
							style="min-height: 200px;" 
						></textarea>
					</div>
					<button v-on:click="update_option" type="button" class="btn btn-success">
						Done
					</button>
				</div>
			</div>
			<div v-if="options_edit === null" class="col-12">
				<div class="card h-100 d-flex align-items-center justify-content-center p-4">
					<button v-on:click="add_option()" type="button" class="btn btn-outline-success">
						Add Item
					</button>
				</div>
			</div>
		</div>

		<div v-else-if="type == 'plugins'">

			<div class="row row-cols-1 row-cols-xxl-2 position-relative">

				<div class="h-100 border-end position-absolute top-0 start-50 translate-middle-x p-0 d-none d-xxl-block" style="width: 1px;"></div>

				<div class="col">

					<label class="form-label fw-bold d-flex align-items-center mb-3">
						<span class="material-icons-outlined me-2">extension</span>
						Included plugins
					</label>
					
					<template v-if="value && value.length">
						<div v-for="(plugin, plugin_key) in value" :key="'selected_plugin-' + plugin_key" class="border-top pt-3 pe-4 mb-3">
							<img :src="plugin.icon" height="40" class="mb-3 rounded-circle" />
							<div>
								<p v-html="plugin.name" class="fw-bold mb-2"></p>
								<p class="small m-0">{{ plugin.description }}</p>
							</div>
							<div class="mt-3 d-flex align-items-center justify-content-between small">
								<button type="button" v-on:click="remove_plugin( plugin_key )" class="bg-transparent p-0 border-0 text-primary d-flex align-items-center text-danger">
									<span class="material-icons-outlined me-2">close</span>
									Remove
								</button>
							</div>
						</div>
					</template>
					<p v-else class="small">No plugins have been included so far.</p>
				</div>

				<div class="col">

					<div class="h-100 card bg-light p-2">
						<input type="search" name="plugin_keyword" v-model="plugin_keyword" v-on:keyup="plugin_delay" class="form-control mb-2" placeholder="Search for plugin...">

						<template v-if="!plugin_loading">
							<template v-if="plugins.length">
								<div v-for="plugin in plugins" :key="'plugin-' + plugin.slug" class="border bg-white rounded p-3 mb-2 d-flex align-items-center justify-content-between" style="border-bottom-width: 3px !important;" :class="is_plugin_selected( plugin.name ) ? 'd-none' : ''">
									<div class="d-flex align-items-center">
										<img :src="plugin.icons['1x']" height="40" class="me-3 rounded-circle" />
										<p v-html="limitString( plugin.name, 50 )" class="fw-bold m-0"></p>
									</div>
									<button type="button" class="btn btn-outline-success btn-sm d-flex align-items-center ms-3" v-on:click="select_plugin({
											name: plugin.name,
											description: plugin.short_description,
											icon: plugin.icons['1x'],
											slug: plugin.slug
										})">
										<span class="material-icons-outlined">add</span>
									</button>
								</div>
							</template>
							<p v-else class="mb-0 small text-center">No plugins found. Please refine your search.</p>
						</template>
						<div v-else class="text-center">
							<div class="spinner-border spinner-border-sm" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>

					</div>
				</div>

			</div>

		</div>

		<input 
			v-else
			:id="name" 
			:name="name" 
			:type="type" 
			:placeholder="( placeholder ? placeholder : '' )" 
			:class="( field_class ? field_class : 'form-control' )" 
			:required="required" 
			v-model="value"
			autofocus
		>

		<div v-if="( error && error.errors && error.errors[name] || ( file_errors ) )" class="invalid-feedback d-block">
			{{ error && error.errors && error.errors[name] ? error.errors[name][0] : file_errors[0] }}
		</div>

		<div v-else-if="help" class="form-text">{{ help }}</div>

	</div>

</template>

<script>
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import site_service from '@/services/site_service'
import team_service from '@/services/team_service'
import wordpress_service from '@/services/wordpress_service'

import { QuillEditor, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
	name: 'components.form_control',

	props: {
		accept: String,
		group_class: String,
		field_class: String,
		label_class: String,
		name: String,
		type: String,
		placeholder: String,
		label: String,
		help: String,
		options: Array,
		pages: Array,
		error: Object,
		include_delay: Boolean,
		required: Boolean,
		input_key: String,
		default_value: String,
		team_id: Number,
		is_ai: Boolean,
		is_format: Boolean,
		icon: String,
		is_thin: Boolean,
		modelValue: [String, Number, Object, Boolean, Array]
	},

	emits: [
		'update:modelValue',
		'update:isAi',
		'update:isFormat',
		'blur'
	],

	components: {
		ColorPicker,
		QuillEditor
	},

	computed: {

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.add_delay( value )				
			}
		},
	},

	data() {
		return {
			delay_timer: null,
			delay: 0,
			loading: false,
			file_errors: null,
			color: null,
			editorOptions: {
				scrollingContainer: '.quill-scroll'
			},
			options_edit: null,
			internalContent: '',
			editorReady: false,
			feature_labels: {
				media: 'Media gallery',
				cta: 'Call to Action',
				form: 'Form',
				logos: 'Logos',
				posts: 'Related Pages',
				subposts: 'Sub Pages'
			},
			plugin_loading: false,
			plugin_keyword: null,
			plugins: []
		}
	},

	watch: {
		value: {
			immediate: true,
			handler(newValue) {
				if (this.editorReady) {
					this.internalContent = newValue;
				}
			}
		},
		internalContent(newValue) {
			if (newValue !== this.value) {
				this.add_delay(  newValue );
			}
		}
	},

	mounted() 
	{
		this.loading = false

		if ( this.include_delay ) {
			this.delay = 500
		}

		if ( this.type == 'options' && !this.modelValue ) {
			this.value = []
		}

		if ( this.type == 'quill' ) {
			this.$nextTick(() => {
				setTimeout(() => {
					if ( this.$refs['quill-' + this.name] ) {
						this.setupCustomClipboard();
						this.registerCustomBlot();
						this.setupCustomToolbar();
						this.editorReady = true;
						this.internalContent = this.value;
					}
				}, 300);
			});
		}

		var $ = window.$

		var vm = this;

		$( document ).on( 'focus, keyup', '.ql-editing input', function() {
			var parent = $( this ).parent( '.ql-editing' );

			$('#exampleList').remove();

			var html = ``;

			if ( vm.pages ) {
				vm.pages.forEach((row) => {
					html += `<option value="` + row.ID + `-` + row.post_title + `">`
				});

				parent.append( `<datalist id="exampleList">` + html + `</datalist>` );

				$( this ).attr( 'list', 'exampleList' ).attr( 'placeholder', 'Start typing a page name' );
			}
		} );

		if ( this.type == 'plugins' ) {
			this.get_plugins()
		}

	},

	methods: {
		changeColor(color) {
			this.color = color
		},

		updateColor()
		{
			var $ = window.$

			$( '.dropdown' ).dropdown( 'hide' );
		},

		add_delay( value )
		{
			let vm = this
			clearTimeout( this.delay_timer );
			this.delay_timer = setTimeout(function() {
				if ( vm.include_group && vm.group && value ) {
					vm.$emit('update:modelValue', value + vm.group)
				} else {
					vm.$emit('update:modelValue', value)
				}

			}, this.delay);
		},

		update_option()
		{
			this.options_edit = null

			this.$emit('update:modelValue', this.value)
		},

		add_option()
		{
			this.value.push( {
				name: '',
				description: ''
			} )

			this.options_edit = ( this.value.length - 1 )

			this.$emit('update:modelValue', this.value)
		},

		remove_option( index )
		{
			this.value.splice( index, 1 );

			this.options_edit = null

			this.$emit('update:modelValue', this.value)
		},

		plugin_delay()
		{
			let vm = this
			clearTimeout( this.delay_timer );
			this.delay_timer = setTimeout(function() {
				vm.get_plugins()
			}, this.delay);
		},

		async get_plugins()
		{
			this.plugin_loading = true

			await wordpress_service.plugins( this.plugin_keyword ).then(( response ) => {
				if ( response.data && response.data.plugins ) {
					this.plugins = response.data.plugins
				} else {
					this.plugins = []
				}				
			}).catch((error) => {
				var error_obj 	= error
				this.file_errors = error_obj.errors ? error_obj.errors['file'] : [error_obj.message]
			}).finally(() => {
				this.plugin_loading 	= false
			})
		},

		is_plugin_selected( name )
		{
			if (this.value && this.value.length) {
				return this.value.some(item => {
					if (item.name == name) {
						return true;
					}
					return false;
				});
			}
			return false;
		},

		select_plugin(plugin)
		{
			if ( !this.value ) {
				this.value = []
			}
			this.value.push( plugin ) 
			this.$emit('update:modelValue', this.value)
		},

		remove_plugin(index)
		{
			this.value.splice(index, 1) 
			this.$emit('update:modelValue', this.value)
		},

		async store_media( event )
		{
			this.loading = true

			this.file_errors = null

			let form_data = new FormData();
			form_data.append('file', event.target.files[0]);

			await site_service.store_media( this.$route.params.workspace_id, this.$route.params.site_id, form_data ).then(( response ) => {
				this.$emit('update:modelValue', response.data)
			}).catch((error) => {
				var error_obj 	= error
				this.file_errors = error_obj.errors ? error_obj.errors['file'] : [error_obj.message]
			}).finally(() => {
				this.loading 	= false
			})
		},

		async store_media_team( event )
		{
			this.loading = true

			this.file_errors = null

			let form_data = new FormData();
			form_data.append('file', event.target.files[0]);

			await team_service.store_media( this.team_id, form_data ).then(( response ) => {
				this.$emit('update:modelValue', response.data.url)
			}).catch((error) => {
				var error_obj 	= error
				this.file_errors = error_obj.errors ? error_obj.errors['file'] : [error_obj.message]
			}).finally(() => {
				this.loading 	= false
			})
		},

		setupCustomClipboard() {
			const editor = this.$refs['quill-' + this.name].getQuill();
			const editorContainer = editor.container;
			
			editorContainer.addEventListener('paste', (e) => {
				// Prevent the default pasting action
				e.preventDefault();

				// Get the pasted data
				const clipboardData = e.clipboardData || window.clipboardData;
				const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
				const plain_text = clipboardData.getData('text/plain');

				// Get current selection
				const range = editor.getSelection(true);
				if (!range) {
					return; // If there is no selection, we should not proceed
				}

				// Use a timeout to allow the clipboard content to be available
				setTimeout(() => {
					// Sanitize the pasted content
					const sanitizedContent = this.sanitizeContent(pastedData);

					// Insert the sanitized content into the editor at the current cursor position
					editor.clipboard.dangerouslyPasteHTML(range.index, sanitizedContent);
					
					// Set the selection to the end of the newly inserted content
					const newCursorPos = range.index + plain_text.length;
					editor.setSelection(newCursorPos, 0, Quill.sources.SILENT);
				}, 100);
			});
		},

		sanitizeContent(content) {
			// Parse the content
			let parser = new DOMParser();
			let doc = parser.parseFromString(content, 'text/html');
			
			// Define allowed tags
			const allowedTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'ol', 'li', 'a', 'br', 'feature'];
			
			const processNode = (node) => {
				Array.from(node.childNodes).forEach(child => {
					if (child.nodeType === Node.ELEMENT_NODE) {

						const parent = child.parentNode;

						if ( 
							!['feature'].includes(child.nodeName.toLowerCase()) && 
							!this.hasFeatureAncestor(child)
						) {
							Array.from(child.attributes).forEach(attr => {
								if ( attr.name != 'href' ) {
									child.removeAttribute(attr.name);
								}
							});
						}
						
						// If the child is an element
						if ( 
							!allowedTags.includes(child.nodeName.toLowerCase()) && 
							!this.hasFeatureAncestor(child) &&
							!['feature'].includes(child.nodeName.toLowerCase())
						) {

							if ( !['feature'].includes(child.nodeName.toLowerCase()) ) {
								// If the tag is not allowed, unwrap it (replace it with its children)
								
								while (child.firstChild) {
									parent.insertBefore(child.firstChild, child);
								}
							}

							parent.removeChild(child);
							
						} else {
							// If the tag is allowed, process its children
							processNode(child);
						}
					}
				});
			};

			processNode(doc.body);

			let sanitizedHtml = doc.body.innerHTML.replace(/&nbsp;/g, ' ');

			// Return sanitized content
			return sanitizedHtml;
		},

		hasFeatureAncestor(node) {
			let current = node;
			while (current.parentNode) {
				if (current.parentNode.nodeName.toLowerCase() === 'feature') {
					return true;
				}
				current = current.parentNode;
			}
			return false;
		},

		setupCustomToolbar() {

			var $ = window.$;

			const customSelects = document.querySelectorAll('.ql-toolbar .ql-custom');

			customSelects.forEach(select => {
				select.addEventListener('change', () => {
					if ( select.value ) {
						this.handleCustomDropdown(select.value);
					}
				});
			});

			$( document ).on( 'click', '.ql-feature-remove', function(e) {
				e.preventDefault();
				var container = $( this ).parents( 'feature' );
				container.remove();
			} );

			$( document ).on( 'click', '.ql-feature-move-up', function(e) {
				e.preventDefault();
				var container = $( this ).parents( 'feature' );
				var prev = container.prev();
				
				if ( prev.length ) {
					container.insertBefore( prev );
				}
			} );

			$( document ).on( 'click', '.ql-feature-move-down', function(e) {
				e.preventDefault();
				var container = $( this ).parents( 'feature' );
				var next = container.next();
				
				if ( next.length ) {
					container.insertAfter( next );
				}
			} );

		},

		handleCustomDropdown(value) {
			var $ = window.$
			const quill = this.$refs['quill-' + this.name].getQuill();
			
			const range = quill.getSelection();
			if ( range && value ) {
				const prevBlot = this.getPreviousBlot(quill, range.index);
				const isCustomFeatureBlot = (prevBlot.statics.blotName == 'feature')
				quill.insertEmbed(range.index, 'feature', value, Quill.sources.USER);
				const newIndex = isCustomFeatureBlot ? range.index + 1 : range.index + 2;
				quill.setSelection(newIndex, Quill.sources.SILENT);

				$('select.ql-custom').val('').trigger('change');
			}
		},

		getPreviousBlot(quill, index) {
			if (index <= 0) return null;
			let [blot] = quill.getLeaf(index - 1);
			return blot;
		},

		registerCustomBlot() {
			
			var vm = this
			const BlockEmbed = Quill.import('blots/block/embed');

			class CustomFeatureBlot extends BlockEmbed {
				static create( value ) {

					var label = vm.feature_labels[value];

					let node = super.create();
					node.setAttribute('contenteditable', false);
					node.setAttribute('data-label', label);
					node.setAttribute('draggable', true);
					node.setAttribute('data-type', value);
					node.classList.add('ql-feature');

					const htmlString = `
						<p class="ql-feature-name">` + label  + `</p>
						<div class="btn-group">
							<button type="button" class="btn btn-outline-primary ql-feature-move-down"><span class="material-icons-outlined">expand_more</span></button>
							<button type="button" class="btn btn-outline-primary ql-feature-move-up"><span class="material-icons-outlined">expand_less</span></button>
							<button type="button" class="btn btn-outline-primary ql-feature-remove"><span class="material-icons-outlined">close</span></button>
						</div>
					`;

					node.innerHTML = htmlString;
					
					return node;
				}

				static value(node) {
					let value = node.getAttribute('data-type')

					return value;
				}
			}

			CustomFeatureBlot.blotName = 'feature';
			CustomFeatureBlot.tagName = 'feature';
			if (!Quill.imports['formats/feature']) {
				Quill.register('formats/feature', CustomFeatureBlot, true );
			}
		},

		extractTextFromHtml( htmlString ) 
		{
			// Step 1: Parse the HTML string into a DOM object
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlString, "text/html");

			// Step 2: Use querySelector to find the <p> tag with class ql-feature-name
			const pElement = doc.querySelector("p.ql-feature-name");

			// Step 3: Extract and return the inner text, or return null if the element doesn't exist
			return pElement ? pElement.innerText : htmlString;
		},

		strip_tags( html )
		{
			if ( html == null ) {
				return '';
			}
			const doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.body.textContent || '';
		},

		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>
.ql-toolbar.ql-snow {
	font-family: var(--bs-body-font-family);
}
.ql-editor {
	padding: 50px;
	font-size: 14px;
	line-height: 1.6em;
}
.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
	display: none;
}
.ql-editor p, 
.ql-editor ol, 
.ql-editor ul, 
.ql-editor pre, 
.ql-editor blockquote, 
.ql-editor h1, 
.ql-editor h2, 
.ql-editor h3, 
.ql-editor h4, 
.ql-editor h5, 
.ql-editor h6 {
	margin-bottom: 20px;
	font-family: var(--bs-body-font-family);
}
.ql-editor strong {
	font-weight: 700;
}
.ql-snow .ql-editor h1 {

}
.ql-tooltip::before {
	display: none;
}
.ql-snow .ql-tooltip a.ql-preview {
	display: none;
}
.ql-snow .ql-tooltip a.ql-action::after {
	margin-left: 0;
}
.ql-feature {
	background: #f2f2f2;
	width: 100%;
	padding: 1rem;
	border: 1px solid #ddd;
	margin: 0 0 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.ql-feature .ql-feature-name {
	content: attr(data-label);
	font-weight: bold;
	font-family: var(--bs-body-font-family);
	font-size: 16px;
	margin: 0;
}
.ql-feature .btn {
	display: flex;
	align-items: center;
}
.ql-snow .ql-picker.ql-custom .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-custom .ql-picker-label::before, 
.ql-snow .ql-picker.ql-custom .ql-picker-item::before {
	content: attr(data-label);
}
.ql-snow .ql-picker {
	height: auto;
}
.ql-snow .ql-picker.ql-custom {
	width: 210px;
}
.ql-snow .ql-picker-label {
	padding: 0.4rem 1rem 0.4rem 0.5rem;
	border-radius: 3px;
}
.ql-container.ql-snow {
	border: none;
	flex-grow: 1;
	overflow: hidden;
}
</style>
